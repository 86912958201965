import axios from "axios";
import { initializeApp } from "firebase/app";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, sendPasswordResetEmail, updatePassword } from "firebase/auth";
import { addDoc, arrayUnion, collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, serverTimestamp, setDoc, startAfter, updateDoc, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import isViewedProperty from "./isViewedProperty";
import addViewedProperty from "./addViewedProperty";
const serverURL = "https://us-central1-compreimoveis-d0aaa.cloudfunctions.net"
// const serverURL = "http://127.0.0.1:5001/compreimoveis-d0aaa/us-central1"
const firebaseConfig = {
  apiKey: "AIzaSyCqlOB3fj21CC4Mu5RKcicxdofQ_VC7EuY",
  authDomain: "compreimoveis-d0aaa.firebaseapp.com",
  projectId: "compreimoveis-d0aaa",
  storageBucket: "compreimoveis-d0aaa.firebasestorage.app",
  messagingSenderId: "490908360377",
  appId: "1:490908360377:web:938466b770cfa83f764568"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
auth.languageCode = 'pt'; 
export { auth, db, serverURL };

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email, {
      url: 'https://compreimoveis.com/entrar',
    });
    return { success: true, message: 'E-mail de redefinição de senha enviado com sucesso!' };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export async function togglePropertyActive(propertyId) {
  const propertyRef = doc(db, 'properties', propertyId);

  try {
      const docSnap = await getDoc(propertyRef);
      if (docSnap.exists()) {
          const currentActiveStatus = docSnap.data().active;
          await updateDoc(propertyRef, {
              active: !currentActiveStatus
          });
      }
  } catch (error) {
      console.error(error);
  }
}

export async function deleteProperty(propertyId) {
  const propertyRef = doc(db, 'properties', propertyId);

  try {
      const docSnap = await getDoc(propertyRef);
      if (docSnap.exists()) {
          await updateDoc(propertyRef, {
            deletedAt: true,
            active: false
          });
      }
  } catch (error) {
      console.error(error);
  }
}

export async function getNotifications(userId) {
  const result = axios.post(`${serverURL}/fetchNotificationsByUserId`, {
    userId,
    lastDoc: null
  })
  return result
}

export async function toggleFavorite(userId, propertyId) {
  axios.post(`${serverURL}/toggleFavorite`, {
    userId, 
    propertyId
  })
}

export const uploadProfileImage = async (userId, file) => {
  try {
      const formData = new FormData();
      formData.append("image", file); // Passa o arquivo diretamente
      formData.append("data", JSON.stringify({ id: userId }));

      const result = await axios.post(
          `${serverURL}/updateProfileUser`,
          formData,
          {
              headers: {
                  "Content-Type": "multipart/form-data"
              }
          }
      );
      const userData = result.data.data;
      return userData.photoURL;
  } catch (error) {
      console.error("Erro ao fazer upload da imagem:", error);
      return error;
  }
};


export function updateUserInfo(data) {
  const userDocRef = doc(db, "users", data.uid);

  return getDoc(userDocRef)
    .then((userDoc) => {
      if (userDoc.exists()) {
        const updatedData = {
          name: data.name,
          telephone: data.telephone,
          document: data.document,
          creci: data.creci || false,
          myLocation: {
            cep: data.myLocation?.cep,
            city: data.myLocation?.city,
            street: data.myLocation?.street,
            state: data.myLocation?.state,
            neightboard: data.myLocation?.neightboard,
            number: data.myLocation?.number,
            complement: data.myLocation?.complement,
          },
        };

        return updateDoc(userDocRef, updatedData).then(() => {
          return { success: true };
        });
      } else {
        console.error("Nenhum documento encontrado!");
        return null;
      }
    })
    .catch((error) => {
      console.error("Erro ao buscar ou atualizar dados do usuário:", error);
      throw error;
    });
}

export async function getPropertyData(id){
  try {
    const propertyRef = doc(db, "properties", id);
    const result = await getDoc(propertyRef);

    if (result.exists()) {
        var fetchedData = {
            id: result.id, 
            ...result.data()
        };
        
        const userRef = doc(db, "users", fetchedData.ownerId);
        const userResult = await getDoc(userRef);
        if (userResult.exists()) {
            let advertiser = userResult.data();
            let {
                document, myLocation, favorites, 
                newsletter, notifications_enabled,
                properties, type,
                ...rest
            } = advertiser 

            fetchedData = {
                ...fetchedData,
                advertiser: rest,
            }
        }

        return fetchedData;
        
    } else {
        console.log("Nenhum documento encontrado com esse ID.");
        return null;
    }
  } catch (error) {
      console.error("Erro ao obter documento:", error);
  }
}

export async function getAllBlogPosts() {
  console.log('consulta api')
  const blogCollectionRef = collection(db, "blogs"); 
  const querySnapshot = await getDocs(blogCollectionRef);

  const blogPosts = querySnapshot.docs.map(doc => ({
    //id: doc.id,
    ...doc.data()
  }));

  return blogPosts;
}

export async function changePassword (currentPassword, newPassword) {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
      throw new Error("Nenhum usuário está logado.");
  }

  try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);

      await updatePassword(user, newPassword);

      return "Senha alterada com sucesso!";
  } catch (error) {
      if (error.code === "auth/wrong-password") {
          throw new Error ("Senha atual incorreta.");
      } else if (error.code === "auth/weak-password") {
          throw new Error ("A nova senha é muito fraca.");
      } else {
          throw new Error ("Erro ao alterar a senha. Tente novamente mais tarde.");
      }
  }
};

export async function getPropertyList(filters) {
  console.log("consumindo api")
  const propertiesCollection = collection(db, 'properties');

  // Query base
  let constraints = [
    where('active', '==', true),
    where('status', '==', 'ACTIVE'),
    where('deletedAt', '==', null),
  ];

  // Adiciona filtros baseados no array de filtros
  if (filters && filters.length > 0) {
    filters.forEach(filter => {
      if (filter.filterName && filter.value !== undefined) {
        constraints.push(where(filter.filterName, '==', filter.value));
      }
    });
  }

  // Adiciona ordenação
  constraints.push(orderBy('createdAt', 'desc'));

  constraints.push(limit(20))

  // Cria a query final
  const activePropertiesQuery = query(propertiesCollection, ...constraints);

  // Busca os documentos
  const result = await getDocs(activePropertiesQuery);

  // Retorna os documentos formatados
  return result.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));
}



export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                  const { latitude, longitude } = position.coords;
                  resolve({ latitude, longitude });
              },
              (error) => {
                  reject(error.message || "Não foi possível obter a localização.");
              }
          );
      } else {
          reject("Geolocalização não é suportada pelo navegador.");
      }
  });
};

export const clearViewHistory = async (userId) => {
  if (userId === null) return;

  try {
    localStorage.removeItem("viewedProperties");

    const viewsCollectionRef = collection(db, "views");
    const querySnapshot = await getDocs(viewsCollectionRef);

    const updatePromises = querySnapshot.docs.map(async (docSnap) => {
      const data = docSnap.data();
      const viewers = data.viewers || [];

      if (viewers.includes(userId)) {
        const updatedViewers = viewers.filter((viewer) => viewer !== userId);
        const updatedCount = Math.max((data.count || 0) - 1, 0);

        await updateDoc(docSnap.ref, {
          viewers: updatedViewers,
          count: updatedCount,
        });
      }
    });

    await Promise.all(updatePromises);

    return true;
  } catch (error) {
    return false
  }
};


export const trackPropertyView = async (userId, propertyId) => {
  if (userId === null) return;
  if (isViewedProperty(propertyId)) return;

  try {
      const propertyDocRef = doc(db, "views", propertyId);
      const docSnap = await getDoc(propertyDocRef);

      if (docSnap.exists()) {
          const data = docSnap.data();
          const viewers = data.viewers || [];
          if (viewers.includes(userId)) return;

          await updateDoc(propertyDocRef, {
              viewers: arrayUnion(userId),
              count: (data.count || 0) + 1,
          });
      } else {
          await setDoc(propertyDocRef, {
              viewers: [userId],
              count: 1,
          });
      }
      addViewedProperty(propertyId)
  } catch (error) {
      console.error("Erro ao registrar visualização:", error);
  }
};

export const getPropertiesViewedByUser = async (userId, page = 1, pageSize) => {
  try {
    const viewsCollectionRef = collection(db, "views");
    const viewsQuery = query(viewsCollectionRef, where("viewers", "array-contains", userId));
    const viewsSnapshot = await getDocs(viewsQuery);
    const propertyIds = viewsSnapshot.docs.map((doc) => doc.id);
    if (propertyIds.length === 0) return { properties: [], total: 0 };

    const propertiesCollectionRef = collection(db, "properties");
    const propertiesQuery = query(
      propertiesCollectionRef,
      where("active", "==", true),
      where("deletedAt", "==", null)
    );

    const allPropertiesSnapshot = await getDocs(propertiesQuery);
    const allProperties = allPropertiesSnapshot.docs
      .filter((propertyDoc) => propertyDoc.exists() && propertyIds.includes(propertyDoc.id))
      .map((propertyDoc) => ({
        id: propertyDoc.id,
        ...propertyDoc.data(),
      }));

    const total = allProperties.length;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedProperties = allProperties.slice(startIndex, endIndex);

    return { properties: paginatedProperties, total };
  } catch (error) {
    console.error("Erro ao buscar imóveis visualizados:", error);
    throw error;
  }
};

export const getFavoritesPropertiesByUser = async (userId, page = 1, pageSize) => {
  console.log("consultando")
  try {
    const propertiesCollectionRef = collection(db, "properties");
    const propertiesQuery = query(
      propertiesCollectionRef,
      where("likedBy", "array-contains", userId), 
      where("active", "==", true), 
      where("deletedAt", "==", null) 
    );

    const propertiesSnapshot = await getDocs(propertiesQuery);
    const allProperties = propertiesSnapshot.docs.map((propertyDoc) => ({
      id: propertyDoc.id,
      ...propertyDoc.data(),
    }));

    const total = allProperties.length;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedProperties = allProperties.slice(startIndex, endIndex);

    return { properties: paginatedProperties, total };
  } catch (error) {
    console.error("Erro ao buscar imóveis favoritos:", error);
    throw error;
  }
};

export const getPropertiesByUser = async (userId, page = 1, pageSize) => {
  try {
    const propertiesCollectionRef = collection(db, "properties");
    const propertiesQuery = query(
      propertiesCollectionRef,
      where("ownerId", "==", userId), 
      where("deletedAt", "==", null) 
    );

    const propertiesSnapshot = await getDocs(propertiesQuery);
    const allProperties = propertiesSnapshot.docs.map((propertyDoc) => ({
      id: propertyDoc.id,
      ...propertyDoc.data(),
    }));

    const total = allProperties.length;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedProperties = allProperties.slice(startIndex, endIndex);

    return { properties: paginatedProperties, total };
  } catch (error) {
    console.error("Erro ao buscar imóveis favoritos:", error);
    throw error;
  }
};

export const reportProperty = async (reporter, propertyId) => {
  if (reporter === null) return;

  try {
    const moment = new Date().toISOString();
    const report = {
      reporter,
      property: propertyId,
      moment,
    };

    const reportRef = await addDoc(
      collection(db, "reports"), 
      report
    );

    return { success: true, reportId: reportRef.id };
  } catch (error) {
    return { success: false, error };
  }
};

export const sendNewAlertMessage = async(data) => {
  const result = axios.post(`${serverURL}/addAlert`, data)
}
/*
export const createChat = async (data) => {
  try {
    const user = data.currentUser;
    if (user === null) return;
    
    if (user === data.ownerId) {
      alert("Você não pode abrir um chat para seu próprio anúncio");
      return false;
    }

    const userRef = doc(db, "users", data.ownerId);
    const userOwner = (await getDoc(userRef)).data();

    const chatQuery = query(
      collection(db, "chats"),
      where("propertyId", "==", data.id),
      where("ownerId", "==", data.ownerId),
      where("participantId", "==", user)
    );

    const querySnapshot = await getDocs(chatQuery);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].id;
    } else {
      const novoChat = await addDoc(collection(db, "chats"), {
        propertyId: data.id,
        ownerId: data.ownerId,
        ownerName: userOwner?.name,
        participantId: user,
        participant: { name: data.name },
        body: [],
      });
      sendNewAlertMessage({
        action: "Enviou uma nova mensagem para você",
        data: {
          chatId: novoChat,
          userName: user.name,
          userProfile: user.photoURL || "",
        },
        ownerId: data.uid === ownerId ? participantId : ownerId,
        type: "CHAT",
      })
      return novoChat.id; 
    }
  } catch (err) {
    console.log(err)
  }
};



// getUserLocation().then(result => {
//   console.log(result)
// })

*/