import { useEffect, useState } from "react";
import WrapperSuggestions from "./Suggestions-styles";
import Img from "../../imgs/categories/Batipin-Flat-by-studioWOK-03.jpg"
import SuggestionCard from "../suggestion_card/SuggestionCard";
import { getAllBlogPosts } from "../../hooks/firebase";
import { useQuery } from "@tanstack/react-query";

const Suggestions = () => {
    /*
    const [data, setData] = useState(null)

    useEffect(() => {

        if (data === null) {
            getAllBlogPosts().then(result => {
                setData(result.map(blog => ({
                    img: blog.bannerMobile,
                    title: blog.title,
                    date: blog.upload,
                    theme: blog.theme,
                    link: `/blog/${blog.slug}`
                })))
            })
        }
    },[])
    */
    const { data: allPosts, isLoading, error } = useQuery({
        queryKey: ["blogPostsTerm"],
        queryFn: getAllBlogPosts,
        staleTime: 60 * 60 * 1000,
        retry: false,
    });
    if (isLoading) return <div>Carregando...</div>;
    if (error) return <div>Ocorreu um erro ao carregar os dados.</div>;
    const data = allPosts.map(blog => ({
        img: blog.bannerMobile,
        title: blog.title,
        date: blog.upload,
        theme: blog.theme,
        link: `/blog/${blog.slug}`
    }));

    return <WrapperSuggestions>
        <h2 className="title">Blog do Compre</h2>
        <div className="container">
            {data != null ? 
                data.map((suggestion) => {
                    return <SuggestionCard 
                        img={suggestion.img}
                        title={suggestion.title}
                        date={suggestion.date}
                        theme={suggestion.theme}
                        link={suggestion.link}
                    />
                })
                :
                <span>Eskeleton</span>
            }
        </div>
    </WrapperSuggestions>
}

export default Suggestions