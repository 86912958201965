import { Link } from "react-router-dom";
import SBlogHome from "./styles";
import { useQuery } from "@tanstack/react-query";
import { getAllBlogPosts } from "../../hooks/firebase";

export default function BlogHome(props) {
    const { data: allPosts, isLoading, error } = useQuery({
        queryKey: ["blogPostsTerm"],
        queryFn: getAllBlogPosts,
        staleTime: 60 * 60 * 1000,
    });

    if (isLoading) return <div>Carregando...</div>;
    if (error) return <div>Ocorreu um erro ao carregar os dados.</div>;

    const truncateText = (text, maxLength = 60) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    };

    const main = allPosts?.find(blog => blog.isMain) || {};
    const another = allPosts
        ?.filter(blog => !blog.isMain)
        .map(blog => ({
            ...blog,
            body: truncateText(blog.body),
        })) || [];

    const BlogComponent = props => {
        return (
            <div className="blog" data-sa={props.sa}>
                <Link className="img" to={props.link}>
                    <img src={props.img} alt={props.title} />
                </Link>
                <div className="infos">
                    <span className="title">{props.title}</span>
                    <span className="snippet">{props.snippet}</span>
                    <Link className="readMore" to={props.link}>
                        Leia Mais
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            className="bi bi-chevron-double-right"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fillRule="evenodd"
                                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                            />
                            <path
                                fillRule="evenodd"
                                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                            />
                        </svg>
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <SBlogHome>
            <div className="blogs">
                <span className="title" data-sa="left">
                    Tendências
                </span>
                <div className="primary" data-sa="left">
                    <Link to={main.slug}>
                        <img src={main.bannerDesktop} alt={main.title} />
                    </Link>
                    <span className="title">{main.title}</span>
                    <span className="snippet">{truncateText(main.body)}</span>
                    <Link className="readMore" to={main.slug}>
                        Leia Mais
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            className="bi bi-chevron-double-right"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fillRule="evenodd"
                                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                            />
                            <path
                                fillRule="evenodd"
                                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                            />
                        </svg>
                    </Link>
                </div>
                <div className="secondary">
                    {another.map(blog => (
                        <BlogComponent
                            key={blog.slug}
                            sa="right"
                            img={blog.bannerMobile}
                            title={blog.title}
                            snippet={blog.body}
                            link={blog.slug}
                        />
                    ))}
                </div>
            </div>
        </SBlogHome>
    );
}
