import { useNavigate, useParams } from "react-router-dom";
import WrapperViewProperty from "./ViewProperty.styles";
import { useEffect, useRef, useState } from "react";
import ImgBase from "../../imgs/low-angle-shot-facade-white-modern-building-blue-clear-sky.jpg"
import { FaHeart, FaUserAlt } from "react-icons/fa";
import { RxRulerSquare } from "react-icons/rx";
import { IoBedOutline } from "react-icons/io5";
import { FaShower } from "react-icons/fa";
import { IoCarOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { LiaShowerSolid } from "react-icons/lia";
import { RiMapPinLine } from "react-icons/ri";
import HouseAdvertisementSession from './../../components/house_advertisement_session/HouseAdvertisementSession';
import SlideShowTypeC from "../../components/slide_show_type_c/SlideShowTypeC";
import { db, getPropertyData, reportProperty, sendNewAlertMessage, toggleFavorite, trackPropertyView } from "../../hooks/firebase";
import { getFirestore, doc, updateDoc, arrayUnion, getDoc, arrayRemove, query, collection, where, getDocs, addDoc } from "firebase/firestore";
import { useContext } from "react";
import { AuthContext } from './../../context/AuthContext';
import { FaRegHeart } from "react-icons/fa";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { NotificationContext } from "../../context/NotificationContext";
import MapProperty from "../../components/map-property/MapProperty";
import { cachePropertyData, clearCacheOfProperty } from "../../hooks/EXCS";

const MapEmbed = ({ latitude, longitude }) => {
    // Formata a URL do iframe com as coordenadas fornecidas
    const iframeSrc = `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d238731.5724573579!2d${longitude}!3d${latitude}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94bc52ce6910005f%3A0xb39d5c0e3b8c5675!2sLocationName!5e0!3m2!1spt-BR!2sbr`;
  
    return (
      <iframe
        src={iframeSrc}
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    );
};


const ViewProperty = () => {
    const { id } = useParams()
    const [propertyId, setPropertyId] = useState(id)
    const [data, setData] = useState(null)
    const [updatedInputs, setUpdatedInputs] = useState([]);
    const {currentUser, userData} = useContext(AuthContext)
    const [isFavorited, setIsFavorites] = useState(false)
    const [isPropertyFromCurrentUser, setIsPropertyFromCurrentUser] = useState(false)
    const navigate = useNavigate()
    const {addNotification} = useContext(NotificationContext)

    const handleReportProperty = async() => {
        const isOwner = data.ownerId === currentUser
        if (isOwner) return addNotification("Você não pode denúnciar você mesmo", "info")

        const result = await reportProperty(currentUser, id)
        result ?
            addNotification("Denúncia realizada!", "info") :
            addNotification("Algo de errado ocorreu", "info")
    }

    const gerarLinkWhatsApp = (telefone) => {
        const mensagem = "Olá, venho através do seu anuncio no alugueimoveis.com";
        const mensagemCodificada = encodeURIComponent(mensagem);
        return `https://wa.me/${telefone}?text=${mensagemCodificada}`;
      };
     
    
    const handleOpenWhatsapp = (telefone) => {
        const numeroApenas = telefone.replace(/\D/g, '');
        const telephone = `55${numeroApenas}`;
        const linkWhatsApp = gerarLinkWhatsApp(telephone);
        window.open(linkWhatsApp, '_blank'); // Abre em nova aba
    };

    const handleCreateChat = () => {
        createChat().then(chatId => {
            if (chatId) {
                navigate(`/chat/${chatId}`)
            }
        })
    }

    const createChat = async () => {
        try {
          const user = userData;
          if (user === null) return;
          
          if (user.uid === data.ownerId) {
            addNotification("Você não pode abrir um chat para seu próprio anúncio", "info");
            return false;
          }
      
          const userRef = doc(db, "users", data.ownerId);
          const userOwner = (await getDoc(userRef)).data();

      
          const chatQuery = query(
            collection(db, "chats"),
            where("propertyId", "==", data.id),
            where("ownerId", "==", data.ownerId),
            where("participantId", "==", user)
          );
      
          const querySnapshot = await getDocs(chatQuery);
      
          if (!querySnapshot.empty) {
            return querySnapshot.docs[0].id;
          } else {
            const novoChat = await addDoc(collection(db, "chats"), {
              propertyId: data.id,
              ownerId: data.ownerId,
              ownerName: userOwner?.name,
              participantId: user.uid,
              participant: { name: userData.name },
              body: [],
            });
            sendNewAlertMessage({
                action: "Enviou uma nova mensagem para você",
                data: {
                  chatId: novoChat.id,
                  userName: user.name,
                  userProfile: user.photoURL || "",
                },
                ownerId: user.uid === data.ownerId ? user.uid : data.ownerId,
                type: "CHAT",
            })
            return novoChat.id; 
          }
        } catch (err) {
          console.log(err)
        }
    };
      
    
    const inputs = [
        { id: "academia", label: "Academia", value: false },
        { id: "churrasqueira", label: "Churrasqueira", value: false },
        { id: "aceita_pets", label: "Aceita Pets", value: false },
        { id: "piscina_aquecida", label: "Piscina Aquecida", value: false },
        { id: "alarme", label: "Alarme", value: false },
        { id: "closet", label: "Closet", value: false },
        { id: "portao_eletrico", label: "Portão Elétrico", value: false },
        { id: "aquecedor_solar", label: "Aquecedor Solar", value: false },
        { id: "cozinha_planejada", label: "Cozinha Planejada", value: false },
        { id: "sala_de_jantar", label: "Sala de Jantar", value: false },
        { id: "ar_condicionado", label: "Ar Condicionado", value: false },
        { id: "elevador", label: "Elevador", value: false },
        { id: "varanda", label: "Varanda", value: false },
        { id: "area_gourmet", label: "Área Gourmet", value: false },
        { id: "energia_solar", label: "Energia Solar", value: false },
        { id: "mobiliado", label: "Mobiliado", value: false },
        { id: "area_de_serviço", label: "Área De Serviço", value: false },
        { id: "escritorio", label: "Escritório", value: false },
        { id: "seguranca_24h", label: "Segurança 24h", value: false },
        { id: "armario_embutido", label: "Armário Embutido", value: false },
        { id: "home", label: "Home", value: false },
        { id: "permite_animais", label: "Permite Animais", value: false },
        { id: "banheira", label: "Banheira", value: false },
        { id: "interfone", label: "Interfone", value: false },
        { id: "lavabo", label: "Lavabo", value: false },
        { id: "cameras_de_seguranca", label: "Câmeras de Segurança", value: false },
        { id: "lavanderia", label: "Lavanderia", value: false },
        { id: "sala_de_tv", label: "Sala de Tv", value: false },
        { id: "cerca_eletrica", label: "Cerca Elétrica", value: false },
        { id: "piscina", label: "Piscina", value: false },
    ];

    useEffect(() => {
        const fetchAndSetPropertyData = async () => {
            try {
                const fetchedData = await getPropertyData(id);
                if (fetchedData.active === false) {
                    navigate("/");
                    return;
                }
                if (currentUser === fetchedData.ownerId) setIsPropertyFromCurrentUser(true);
                if (fetchedData.likedBy.includes(currentUser)) setIsFavorites(true);
                if (fetchedData.otherItems) {
                    const otherItems = fetchedData.otherItems.map(item => item.id);
                    const updated = inputs.map(input => ({
                        ...input,
                        value: otherItems.includes(input.id),
                    }));
    
                    setUpdatedInputs(updated);
                }
    
                setData(fetchedData);
                trackPropertyView(currentUser, id);
    
                window.scrollTo({
                    top: 0,
                });
            } catch (error) {
                console.error("Erro ao buscar os dados do imóvel:", error);
            }
        };
    
        fetchAndSetPropertyData();
    }, [id]);
    

    const handleToggleFavorite = (e) => {
        if (currentUser && currentUser !== data.ownerId) {
            e.currentTarget.classList.toggle("actived")
            toggleFavorite(currentUser, data.id)
            setIsFavorites(!isFavorited)

        }
    }

    return <WrapperViewProperty>
        {data !== null && 
            <>
                <SlideShowTypeC images={data.images} />
                <div className="actions">
                    {isPropertyFromCurrentUser ? 
                    <span className="button deactivate" onClick={() => addNotification("Você não pode favoritar seu anúncio", "info")}>
                        <FaRegHeart />
                        Favoritar
                    </span>
                    : isFavorited ? 
                        <>
                            <span className="button actived" onClick={handleToggleFavorite}>
                            <FaHeart />
                            Favorito
                        </span>
                        {/* <span className="message">Outras pessoas também favoritaram este imóvel!</span> */}
                        </>
                        :
                        <span className="button" onClick={handleToggleFavorite}>
                            <FaRegHeart />
                            Favoritar
                        </span>
                    }
                </div>
                <div className="infos">
                    <span className="location">{data.street}{data.number ? " "+data.number : ""}, {data.neightboard}, {data.city} - {data.state}</span>
                    <div className="left">
                        <div className="infos-container">
                            {["Sítio/Fazenda"].includes(data.type) &&
                                <span className="info">
                                    <RxRulerSquare />
                                    {data.alqueiro}
                                    {" Alqueiro"}
                                </span>
                            }
                            {["Área Comercial", "Terreno", "Terreno Em Condomínio", "Terreno Comercial"].includes(data.type) &&
                                <span className="info">
                                    <RxRulerSquare />
                                    {data.squareMeter}
                                    {" m²"}
                                </span>
                            }
                            {!["Área Comercial", "Terreno", "Terreno Em Condomínio", "Terreno Comercial", "Sítio/Fazenda"].includes(data.type) &&
                                <span className="info">
                                    <RxRulerSquare />
                                    {data.squareConstruction}
                                    {" m²"}
                                </span>
                            }
                            {!["Prédio Comercial", "Terreno", "Terreno Em Condomínio", "Terreno Comercial", "Galpão/Armazém", "Área Comercial", "Sítio/Fazenda", "Sala Comercial"].includes(data.type) &&
                                <span className="info">
                                    <IoBedOutline />
                                    {data.bedroom}
                                    {` Quarto${data.bedroom === 1 ? "" : "s"}`}
                                </span>
                            }
                            {!["Área Comercial", "Terreno", "Terreno Em Condomínio", "Terreno Comercial", "Sítio/Fazenda"].includes(data.type) &&
                                <span className="info">
                                    <LiaShowerSolid />
                                    {data.bathroom}
                                    {` Banheiro${data.bathroom === 1 ? "" : "s"}`}
                                </span>
                            }
                            {!["Área Comercial", "Terreno", "Terreno Em Condomínio", "Terreno Comercial", "Sítio/Fazenda"].includes(data.type) &&
                                <span className="info">
                                    <IoCarOutline />
                                    {data.garage}
                                    {` Vaga${data.garage === 1 ? "" : "s"}`}
                                </span>
                            }
                        </div>
                        <span className="price">R$ {(data.price).toLocaleString("pt-BR")+",00"}</span>
                        {/** <span className="extras">Condomínio R$ {data.condominiumPrice} • IPTU R$ {data.iptu}</span> */}
                        {data.description.length > 0 &&
                            <div className="description">
                                <span className="title">Descrição: </span>
                                <span className="content">{data.description}</span>
                            </div>
                        }
                        {data.otherItems && data.otherItems.length > 0 && 
                            <div className="about">
                            <span className="title">Outros Itens:</span>
                            {updatedInputs.map((input) => {
                                let isActive = input.value
                                if (!isActive) return <></>;
                                return <div key={input.id} className="spec">
                                    <input
                                        type="radio"
                                        id={input.id}
                                        checked={input.value}
                                        readOnly
                                    />
                                    <label htmlFor={input.id}>{input.label}</label>
                                </div> 
                            })}
                            </div>
                        }
                    </div>
                    <div className="advertiser">
                        <span className="title">Contato</span>
                        <div className="user">
                            {data.advertiser?.photoURL !== "" ?
                                <img src={data.advertiser?.photoURL} alt="" />
                                :
                                <div className="user-icon">
                                    <FaUserAlt />
                                </div>
                            }
                            <div className="about">
                                <span className="name">{data.advertiser?.name}</span>
                                {data.advertiser.creci &&
                                    <>
                                        <span className="function">Corretor(a) de Imóveis</span>
                                        <span className="creci">{data.advertiser?.creci}</span>
                                    </>
                                } 
                            </div>
                        </div>
                        {data.contactIsVisible ? <>
                            <span className="button email">{data.advertiser.email}</span>
                            {data.advertiser.telephone !== "" &&
                                <span className="button whatsapp" onClick={() => handleOpenWhatsapp(data.advertiser.telephone)}> <FaWhatsapp/> Whatsapp</span>
                            }
                        </>
                        :
                        ""    
                        }
                        <span className="button chat" onClick={handleCreateChat}>Chat</span>
                        <span className="info">Ao enviar, você está aceitando os Termos e condições de uso e as Políticas de privacidade</span>
                        <span className="report" onClick={handleReportProperty}>Denunciar</span>
                    </div>
                </div>
                
                <div className="location">
                    <span className="title">Localização do imóvel</span>
                    <span className="address">
                        <RiMapPinLine />
                        {data.street + ", "}
                        {data.number ? data.number + ", " : ""}
                        {data.neightboard + ", "}
                        {data.city + " "}
                    </span>
                    {/* <GoogleMap>
                        <Marker 
                            position={{lat: data.coords.latitude, lgn: data.coords.longitude}}
                        />
                    </GoogleMap> */}
                    <MapProperty property={data} />
                    {/* <MapEmbed latitude={data.coords.latitude} longitude={data.coords.longitude} /> */}
                </div>
                <div className="recomendations">
                    <HouseAdvertisementSession title="Você também pode gostar desses imóveis:" />
                </div>
            </>
        } 
    </WrapperViewProperty>
}

export default ViewProperty